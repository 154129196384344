import React, { useState } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import {
  CFView,
  CFImage,
  DefaultScreen,
  MobileScreen,
  CFLink,
} from 'components'
import {
  logo,
  logoTitle,
  viewMenu,
  menu1,
  menu2,
  menu3,
  menu4,
  menu5,
  menu6,
  menu7,
  menu8,
  menu9,
  menu10,
  menu11,
  menu12,
} from 'images'

const orderingUrl =
  process.env.REACT_APP_ENVIRONMENT === 'dev'
    ? 'https://demo.codefusion.tech/restaurants/ebisu/locations/ebisuLocation1'
    : 'https://order.codefusion.tech/restaurants/nqeoaepgw1d0XIULFk73/locations/PcCPpuqPbaOEZeLx9xHC'

const images = [
  {
    src: menu1,
  },
  {
    src: menu2,
  },
  {
    src: menu3,
  },
  {
    src: menu4,
  },
  {
    src: menu5,
  },
  {
    src: menu6,
  },
  {
    src: menu7,
  },
  {
    src: menu8,
  },
  {
    src: menu9,
  },
  {
    src: menu10,
  },
  {
    src: menu11,
  },
  {
    src: menu12,
  },
]

export default () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <MobileScreen>
        <CFView
          column
          center
          h="70px"
          bg="rgba(0,0,0,.95)"
          boxShadow="0 1px 3px rgba(0,0,0,.8)"
        >
          <CFView row center alignCenter>
            <CFImage
              h="50px"
              mt="5px"
              src={logo}
              alt="Cafe Salade de Fruits Logo"
            />
            <CFImage
              h="35px"
              ml="15px"
              src={logoTitle}
              alt="Cafe Salade de Fruits Logo Title"
            />
          </CFView>
          {/* <CFView onClick={() => setShowModal(true)} hover>
            <CFImage
              h="50px"
              src={viewMenu}
              alt="Cafe Salade de Fruits View Menu Button"
            />
          </CFView> */}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          row
          justifyStart
          h="70px"
          bg="rgba(0,0,0,.95)"
          boxShadow="0 1px 3px rgba(0,0,0,.8)"
        >
          <CFView
            row
            justifyBetween
            alignCenter
            w="100%"
            maxWidth="1400px"
            ph="35px"
          >
            <CFView row justifyStart mt="75px">
              <CFImage
                h="140px"
                mt="5px"
                src={logo}
                alt="Cafe Salade de Fruits Logo"
              />
              <CFImage
                h="45px"
                mt="15px"
                ml="20px"
                src={logoTitle}
                alt="Cafe Salade de Fruits Logo Title"
              />
            </CFView>
            <CFView hover mt="9px">
              <CFLink href={orderingUrl}>
                <CFImage
                  h="60px"
                  src={viewMenu}
                  alt="Cafe Salade de Fruits View Menu Button"
                />
              </CFLink>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
      <ModalGateway>
        {showModal ? (
          <Modal onClose={() => setShowModal(false)}>
            <Carousel views={images} />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
