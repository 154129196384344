import React from 'react'
import { CFSelect, CFView } from 'components'
import Header from './Header'
import Hero from './Hero'
import Promotions from './Promotions'
import About from './About'
import Gallery from './Gallery'
import Contact from './Contact'
import Footer from './Footer'
import { homebg } from 'images'
import { dispatch } from 'store'
import WelcomeModal from './WelcomeModal'

export default () => {
  return (
    <CFView row justifyCenter>
      <CFView maxWidth="1400px" w="100%" image={`url(${homebg}) center / 30%`}>
        <Header />
        <Hero />
        <Promotions />
        <About />
        <Gallery />
        <Contact />
        <Footer />
      </CFView>
      <CFSelect selector={dispatch.restaurant.getWelcomeMessage}>
        <WelcomeModal />
      </CFSelect>
    </CFView>
  )
}
