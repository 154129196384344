import React from 'react'
import { Parallax } from 'react-parallax'
import { CFImage, CFView, DefaultScreen, MobileScreen } from 'components'
import { mobileAbout, about } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <Parallax bgImage={mobileAbout} bgImageAlt="Entree Dish" strength={500}>
          <div style={{ height: '400px' }} />
        </Parallax>
        {/* <CFView
          column
          center
          h="500px"
          image={`url(${mobileAbout}) center / cover no-repeat`}
        >
          <CFImage src={mobileAbout} w="100%" pt="20px" alt="About" />
        </CFView> */}
      </MobileScreen>
      <DefaultScreen>
        <Parallax bgImage={about} bgImageAlt="Entree Dish" strength={800}>
          <div style={{ height: '500px' }} />
        </Parallax>
        {/* <CFView
          m="0 auto"
          h="500px"
          maxWidth="1400px"
          pt="20px"
        >
          <CFImage src={about} w="100%" alt="About" />
        </CFView> */}
      </DefaultScreen>
    </CFView>
  )
}
