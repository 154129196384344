import React from 'react'
import { CFView, DefaultScreen, MobileScreen } from 'components'
import OrderPickupButton from './OrderPickupButton'
import { hero, mobileHero } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          column
          justifyEnd
          h="90VH"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <CFView column center pb="30px" ph="10px">
            <CFView pt="15px">
              {/* <CFView row center bg="rgba(0,0,0,0.7)" p="10px 20px" br="30px">
                <CFView bold h1 textCenter color="white">
                  Free edamame on your first online order!
                </CFView>
              </CFView> */}
            </CFView>
            <CFView ml="20px" pb="30px">
              <OrderPickupButton />
              {/* <OrderDeliveryButton /> */}
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          column
          justifyEnd
          h="90VH"
          w="100%"
          image={`url(${hero}) center / cover no-repeat`}
          boxShadow="0 2px 5px rgba(0,0,0,.5)"
        >
          <CFView row justifyStart alignCenter pb="30px" ph="30px">
            <CFView row justifyBetween ml="40px">
              <OrderPickupButton />
              {/* <OrderDeliveryButton /> */}
            </CFView>
            {/* <CFView
              row
              center
              bg="rgba(0,0,0,0.7)"
              w="65%"
              ph="20px"
              pv="5px"
              br="30px"
            >
              <CFView bold h1 textCenter color="white">
                Free Edamame on Your 1st Online Order!
              </CFView>
            </CFView> */}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
